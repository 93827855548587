export default [
  {
    path: '',
    component: () => import('@/layouts/MainLayout'),
    meta: {
      requiresAuth: false
    },
    children: [
      { path: '/', redirect: '/dashboard' },
      {
        path: '/dashboard/affiliate',
        name: 'OldDashboard',
        component: () => import('@/pages/Dashboard.vue'),
        meta: {
          role: ['affiliate', 'advertiser', 'admin'],
          requiresAuth: true
        }
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/pages/SmartDashboard.vue'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/invoice/:hash',
        name: 'Invoice',
        component: () => import('@/pages/financial/OutBoundInvoice.vue'),
        props: true,
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/affiliate/invoice/:hash',
        name: 'Invoice Afiliado',
        component: () => import('@/pages/financial/OutBoundInvoice.vue'),
        props: true,
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        }
      },
      {
        path: '/advertiser',
        name: 'Advertiser',
        component: () => import('@/pages/Advertiser.vue'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/advertiser/create/:id?',
        name: 'FormAdvertiser',
        component: () => import('@/components/advertiser/FormAdvertiser.vue'),
        props: true,
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        // async getAffiliateManagerOptions() {
        //   try {
        //     this.onLoading(true);
        //     const { data, status } = await this.$http.get('user/managers');
        //     console.log(data, status);
        //     this.managerOptions = data;
        //   } catch (error) {
        //     this.errorNotify(error);
        //   }
        // }
        path: '/campaigns',
        name: 'Campaigns',
        component: () => import('@/pages/campaign/Campaigns.vue'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },

      {
        path: '/mycampaigns/:myCampaignId?',
        name: 'MyCampaigns',
        component: () => import('@/pages/campaign/MyCampaigns.vue'),
        props: true,
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        }
      },
      {
        path: '/advertiser/invoice',
        name: 'AdvertiserInvoices',
        component: () => import('@/pages/advertiser/AdvertiserInvoice.vue'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },

      {
        path: '/advertiser/invoice/pdf/:id',
        name: 'ShowAdvertiserInvoicePdf',
        component: () => import('@/pages/advertiser/ShowAdvertiserInvoicePDF.vue'),
        props: true,
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/campaigns/manage/:id?',
        name: 'FormCampaign',
        component: () => import('@/components/campaign/FormCampaign'),
        props: true,
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/campaigns/edit/:tabCurrent/:id',
        name: 'EditCampaign',
        component: () => import('@/components/campaign/EditCampaign'),
        props: true,
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/campaigns/adjust_commissions',
        name: 'CommissionAdjustments',
        component: () => import('@/pages/campaign/CommissionAdjustments'),
        props: true,
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/campaigns/validate_clicks',
        name: 'ClickValidation',
        component: () => import('@/pages/campaign/ClickValidation'),
        props: true,
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/campaigns/validate-conversions',
        name: 'ValidateConversions',
        component: () => import('@/pages/campaign/ValidateConversions'),
        props: true,
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/campaigns/affiliate-bonus',
        name: 'AffiliateBonus',
        component: () => import('@/pages/campaign/AffiliateBonus'),
        props: true,
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/campaigns/validation-history',
        name: 'ValidationHistory',
        component: () => import('@/pages/campaign/ValidationHistory'),
        props: true,
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/reports/clicks-legacy',
        name: 'ClickReports',
        component: () => import('@/pages/reports/ClickReports.vue'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/reports/clicks-consolidated',
        name: 'ClickReportsConsolidated',
        component: () => import('@/pages/reports/ClickReportsConsolidated.vue'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/reports/conversions',
        name: 'ConversionReports',
        component: () => import('@/pages/reports/ConversionReports'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/reports/captures',
        name: 'CaptureReports',
        component: () => import('@/pages/reports/CaptureReports'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/reports/creatives',
        name: 'CreativeReports',
        component: () => import('@/pages/reports/CreativeReports'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/advertiser/reports/creatives',
        name: 'AdvertiserCreativeReports',
        component: () => import('@/pages/reports/AdvertiserCreativeReports'),
        meta: {
          role: ['advertiser'],
          requiresAuth: true
        }
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import('@/pages/Users'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/creative',
        name: 'CreativeList',
        component: () => import('@/pages/creative/CreativeList'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/creative/manager',
        name: 'CreativeCreate',
        component: () => import('@/pages/creative/CreativeCreate'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/creative/manager/:idCreative',
        name: 'CreativeView',
        component: () => import('@/pages/creative/CreativeList'),
        props: true,
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/creative/tag/:idCreativeTag',
        name: 'CreativeTag',
        component: () => import('@/pages/creative/CreativeList'),
        props: true,
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/affiliate/tag/:idCreativeTag/:idChannel',
        name: 'AffiliateCreativeTag',
        component: () => import('@/pages/creative/MyCreativeList'),
        props: true,
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        }
      },
      {
        path: '/create-user',
        name: 'Create User',
        component: () => import('@/pages/CreateUser'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/campaigns/include-sites',
        name: 'Include Sites',
        component: () => import('@/pages/campaign/IncludeSites'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/campaigns/master-tag',
        name: 'Master tag',
        component: () => import('@/pages/campaign/MasterTag'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/financial/pi',
        name: 'PI',
        component: () => import('@/pages/financial/PI'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/financial/billing',
        name: 'Billing',
        component: () => import('@/pages/financial/Billing'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/financial/schedule-payments',
        name: 'Schedule Payments',
        component: () => import('@/pages/financial/SchedulePayments'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/financial/approve-invoices',
        name: 'Approve Invoices',
        component: () => import('@/pages/financial/ApproveInvoices'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/financial/pay-invoices',
        name: 'Pay Invoices',
        component: () => import('@/pages/financial/PayInvoices'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/financial/custom-payments',
        name: 'Custom Payments',
        component: () => import('@/pages/financial/CustomPayments'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/financial/bank-statements',
        name: 'BankStatements',
        component: () => import('@/pages/financial/BankStatements'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/financial/affiliate-billing',
        name: 'AffiliateBilling',
        component: () => import('@/pages/financial/AffiliateBilling'),
        meta: {
          role: ['admin'],
          requiresAuth: true
        }
      },
      {
        path: '/affiliate/invoices',
        name: 'AfiliateInvoices',
        component: () => import('@/pages/affiliate/Invoices'),
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        }
      },

      {
        path: '/financial/affiliate',
        name: 'FinancialAffiliate',
        component: () => import('@/pages/affiliate/Financial'),
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        }
      },
      {
        path: '/affiliate/postback',
        name: 'PostBack',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/affiliate/Postback')
      },
      {
        path: '/affiliate/deeplinkadmin',
        name: 'DeeplinkAdmin',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/affiliate/DeeplinkAdmin')
      },
      {
        path: '/affiliate/mailing',
        name: 'Mailing',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/affiliate/Mailing')
      },
      {
        path: '/affiliate',
        name: 'Affiliate',
        meta: {
          role: ['admin'],
          requiresAuth: true
        },
        component: () => import('@/pages/affiliate/Affiliate')
      },
      {
        path: '/channel',
        name: 'Channel',
        meta: {
          role: ['admin'],
          requiresAuth: true
        },
        component: () => import('@/pages/channel/Channel')
      },
      {
        path: '/affiliate/manager/:id',
        name: 'ManagerAffiliate',
        props: true,
        meta: {
          role: ['admin'],
          requiresAuth: true
        },
        component: () => import('@/pages/affiliate/ManagerAffiliate')
      },
      {
        path: '/api/admin/creative',
        name: 'CreativesApiAdmin',
        meta: {
          role: ['admin'],
          requiresAuth: true
        },
        component: () => import('@/pages/apiGenerator/Admin/CreativeAPIPage')
      },
      {
        path: '/api/admin/conversions',
        name: 'ConversionsApiAdmin',
        meta: {
          role: ['admin'],
          requiresAuth: true
        },
        component: () => import('@/pages/apiGenerator/Admin/ConversionsAPIPage')
      },
      {
        path: '/api/admin/deeplink',
        name: 'DeeplinkApiAdmin',
        meta: {
          role: ['admin'],
          requiresAuth: true
        },
        component: () => import('@/pages/apiGenerator/Admin/DeeplinkAPIPage')
      },
      {
        path: '/api/admin/campaign',
        name: 'CampaignsApiAdmin',
        meta: {
          role: ['admin'],
          requiresAuth: true
        },
        component: () => import('@/pages/apiGenerator/Admin/CampaignAPIPage')
      },
      {
        path: '/channel/mychannels',
        name: 'MyChannels',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/channel/MyChannels')
      },
      {
        path: '/creative/mycreatives',
        name: 'MyCreatives',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/creative/MyCreativeList')
      },
      {
        path: '/account/myaccount',
        name: 'MyAccount',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/account/MyAccount')
      },
      {
        path: '/affiliate/reports/clicks-legacy',
        name: 'AffiliateClickReports',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/reports/AffiliateClickReports')
      },
      {
        path: '/affiliate/reports/clicks-consolidated',
        name: 'AffiliateClickReportsConsolidated',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/reports/AffiliateClickReportsConsolidated')
      },
      {
        path: '/affiliate/reports/conversion',
        name: 'AffiliateConversionReports',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/reports/AffiliateConversionReports')
      },
      {
        path: '/api/affiliate/creatives',
        name: 'API cupom e oferta',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/apiGenerator/CouponAndOffer')
      },
      {
        path: '/api/affiliate/deeplink',
        name: 'API Deeplink',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/apiGenerator/DeeplinkApi')
      },
      {
        path: '/api/affiliate/conversions',
        name: 'API Conversion',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/apiGenerator/ConversionApi')
      },
      {
        path: '/api/affiliate/campaigns',
        name: 'API Campaigns',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/apiGenerator/CampaignsApi')
      },
      {
        path: '/api/coupon',
        name: 'API cupom',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/apiGenerator/Coupon')
      },
      {
        path: '/deeplink',
        name: 'Deeplink',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/deeplink/Deeplink')
      },
      {
        path: '/deeplink/batch',
        name: 'DeeplinkBatch',
        meta: {
          role: ['affiliate'],
          requiresAuth: true
        },
        component: () => import('@/pages/deeplink/DeeplinkBatch')
      },
      {
        path: '/advertiser/reports/clicks-legacy',
        name: 'AdvertiserClickReports',
        meta: {
          role: ['advertiser'],
          requiresAuth: true
        },
        component: () => import('@/pages/reports/AdvertiserClickReports')
      },
      {
        path: '/advertiser/reports/click-consolidated',
        name: 'AdvertiserClickReportsConsolidated',
        meta: {
          role: ['advertiser'],
          requiresAuth: true
        },
        component: () => import('@/pages/reports/AdvertiserClickReportsConsolidated')
      },
      {
        path: '/advertiser/reports/conversion',
        name: 'AdvertiserConversionReports',
        meta: {
          role: ['advertiser'],
          requiresAuth: true
        },
        component: () => import('@/pages/reports/AdvertiserConversionReports')
      }
    ]
  },
  {
    path: '',
    component: () => import('@/layouts/DefaultLayout'),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: 'unsubscribed',
        name: 'Unsubscribed',
        meta: {
          requiresAuth: false
        },
        component: () => import('@/pages/Unsubscribed')
      },
      {
        path: 'auth/auto_login/:token',
        name: 'AutoLogin',
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'auth/update_password/:hash?',
        name: 'UpdatePassword',
        meta: {
          requiresAuth: false
        },
        component: () => import('@/pages/UpdatePassword'),
        props: true
      },
      {
        path: 'auth/first_access/:hash?',
        name: 'FirstAccess',
        meta: {
          requiresAuth: false
        },
        component: () => import('@/pages/FirstAccess'),
        props: true
      },
      {
        path: 'auth/:param?/:hash?',
        name: 'Auth',
        meta: {
          requiresAuth: false
        },
        component: () => import('@/pages/Auth'),
        props: true
      }
    ]
  },
  {
    path: '/*',
    redirect: '/auth'
  }
];
